import React, { useState, useEffect, useMemo, useRef } from 'react';
import "./Home.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { VscFeedback } from "react-icons/vsc";

const Home = () => {
    const topics = useMemo(
        () => [
            'Salesforce Admin',
            'Salesforce Development',
            'API Integration',
            'Lightning Web Components',
            'SOQL',
            'SOSL'
        ],
        []
    );

    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    const displayedWord = useMemo(() => topics[currentWordIndex], [currentWordIndex, topics]);

    const [displayedWordStyle, setDisplayedWordStyle] = useState({
        opacity: 0,
        transition: 'opacity 0.8s ease-in-out' // Apply transition for opacity changes
    });

    useEffect(() => {
        let displayTimeout;

        const transitionWord = () => {
            setDisplayedWordStyle({ opacity: 1 }); // Fade in the word
            displayTimeout = setTimeout(() => {
                setDisplayedWordStyle((prevStyle) => ({ ...prevStyle, opacity: 0 })); // Fade out the word
                setTimeout(() => {
                    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % topics.length);
                }, 500); // Wait for the transition to complete before changing the word
            }, 2000); // Hold for 2 seconds before transitioning to the next word

            return () => {
                clearTimeout(displayTimeout);
            };
        };

        transitionWord();

        return () => {
            clearTimeout(displayTimeout);
        };
    }, [currentWordIndex, topics]);

    // testimonials code
    const testimonials = [
    { "feedback": "The support team was incredibly responsive and helped us resolve issues quickly!", "author": "Amit Patel", "position": "CRM Consultant" },
    { "feedback": "This tool has significantly improved our workflow efficiency. Highly recommended!", "author": "Priya Sharma", "position": "Salesforce Administrator" },
    { "feedback": "Integration with our existing systems was smooth and hassle-free. Great experience!", "author": "Rahul Verma", "position": "Technical Architect" },
    { "feedback": "The user experience is fantastic! The interface is clean and easy to navigate.", "author": "Neha Kapoor", "position": "Business Analyst" },
    { "feedback": "I love how intuitive the design is. It has made our daily operations much easier.", "author": "Vikas Gupta", "position": "Product Manager" },
    { "feedback": "The automation features have saved us countless hours of manual work. Excellent!", "author": "Ananya Roy", "position": "Salesforce Developer" },
    { "feedback": "This platform exceeded our expectations with its reliability and smooth performance.", "author": "Manoj Tiwari", "position": "Solution Engineer" },
    { "feedback": "We've seen a noticeable improvement in customer engagement since implementing this.", "author": "Kavita Joshi", "position": "Marketing Cloud Specialist" },
    { "feedback": "The customization options are outstanding! It truly adapts to our business needs.", "author": "Ravi Mehta", "position": "Salesforce Consultant" },
    { "feedback": "I appreciate the detailed analytics and insights provided. It helps with strategic planning!", "author": "Sonia Agarwal", "position": "Data Analyst" },
    { "feedback": "The system is incredibly fast and reliable, making it a pleasure to use daily.", "author": "Deepak Malhotra", "position": "System Administrator" },
    { "feedback": "Our team productivity has skyrocketed since we integrated this tool into our workflow.", "author": "Swati Saxena", "position": "Project Manager" },
    { "feedback": "The API integration was seamless, and we had no trouble setting up custom workflows.", "author": "Arjun Singh", "position": "Integration Specialist" },
    { "feedback": "I am impressed by the security features. Our data remains protected at all times.", "author": "Rohit Bansal", "position": "Cybersecurity Analyst" },
    { "feedback": "This platform has brought excellent efficiency to our sales pipeline management.", "author": "Megha Reddy", "position": "Sales Manager" },
    { "feedback": "The mobile-friendly interface allows us to access critical data on the go.", "author": "Naveen Kapoor", "position": "Field Sales Executive" },
    { "feedback": "We've reduced manual errors drastically thanks to the automation functionalities.", "author": "Pooja Desai", "position": "Quality Assurance Analyst" },
    { "feedback": "Customer satisfaction has improved significantly due to better service tracking.", "author": "Rajesh Iyer", "position": "Customer Success Manager" },
    { "feedback": "The reporting features are top-notch, making it easy to track KPIs effectively.", "author": "Shruti Verma", "position": "Operations Manager" },
    { "feedback": "The AI-powered recommendations have been a game-changer for our business.", "author": "Harsh Trivedi", "position": "AI Consultant" },
    { "feedback": "Collaboration among teams has never been easier. This platform is truly seamless!", "author": "Tanya Mehta", "position": "Collaboration Specialist" },
    { "feedback": "I love how customizable the dashboards are! We can get real-time insights anytime.", "author": "Vikram Choudhary", "position": "BI Analyst" },
    { "feedback": "The migration process was smooth, and we faced no major issues during the transition.", "author": "Sanjay Kulkarni", "position": "Data Migration Expert" },
    { "feedback": "I appreciate the regular updates and enhancements that keep improving the platform.", "author": "Preeti Nair", "position": "Product Specialist" },
    { "feedback": "One of the best investments our company has made. It has truly transformed our workflow.", "author": "Yash Goyal", "position": "Enterprise Architect" }
]


    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    //   const next = () => {
    //     sliderRef.current.slickNext();
    //   };

    //   const previous = () => {
    //     sliderRef.current.slickPrev();
    //   };



    return (
        <>
            {/* Welcome Page DIV  */}
            <div className='mainDiv'>
                <div className='welcomeBox'>
                    <h1 className='text'>Welcome to <span className='welcomeHeading'>Salesforce Campus</span></h1>
                    <h2>
                        <span className='text2'>Learn </span>
                        <span style={displayedWordStyle} className='word-transition'>{displayedWord}</span>
                    </h2>
                    <p className='welcomeBlogs'>
                        Here, you'll dive into the expansive world of Salesforce. Whether you're new to the platform or a seasoned professional, this site offers a wealth of resources, tutorials, and insights tailored to elevate your Salesforce expertise. From mastering Salesforce Administration to exploring Development, honing API Integration skills, delving into Lightning Web Components (LWC), querying with SOQL, and unleashing the power of SOSL, our goal is to empower you with comprehensive learning experiences and practical knowledge. Explore our blogs, tutorials, and services designed to streamline your Salesforce journey. Get ready to unlock your full potential with Salesforce Campus.
                    </p>
                </div>
                <div className='RandomCodeBaseImage'>
                    <img src="/staticImages/Home.jpg" alt="" />
                </div>
            </div>

            {/* Recomended course div */}
            <div className="card-container">
                <h2>Recommended Courses</h2>
                <div className="card">
                    <img src="/staticImages/SF_Admin.jpg" alt="Salesforce Admin" />
                    <h2>Salesforce Admin</h2>
                    <p>Salesforce Admin training offers an immersive journey into configuring and managing Salesforce solutions. Through hands-on modules, it covers user controls, data handling, and customization techniques. This comprehensive course empowers individuals to streamline workflows and harness the full potential of Salesforce's features</p>
                    <button>Start Watching</button>
                </div>
                <div className="card">
                    <img src="/staticImages/SF_Dev.png" alt="Salesforce Developer" />
                    <h2>Salesforce Developer</h2>
                    <p>Salesforce Developer training unveils the art of building and customizing applications on the Salesforce platform. Exploring coding languages like Apex and Lightning components, this course empowers developers to create tailored solutions, integrate third-party tools, and elevate user experiences. Through practical modules, individuals master data modeling, business logic implementation, and cutting-edge development techniques.</p>
                    <button>Start Watching</button>
                </div>
                <div className="card">
                    <img src="/staticImages/LWC.png" alt="Lightning Web Component" />
                    <h2>Lightning Web Component</h2>
                    <p>Learning Lightning Web Components (LWC) immerses developers in the modern approach to building lightning-fast, interactive user interfaces on the Salesforce platform. This specialized training covers the essentials of component-based architecture, JavaScript, and Salesforce APIs, enabling developers to create responsive and scalable web applications. Through hands-on modules, individuals master the art of developing efficient, reusable components for diverse business needs.</p>
                    <button>Start Watching</button>
                </div>
            </div>

            {/* Testimonial */}
            <div className="testimonial-container">
                <h2>Testimonials</h2>
                <div className="testimonial-slider">
                    <Slider ref={sliderRef} {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index}>
                                <div className="testimonial-item">
                                    <VscFeedback />
                                    {/* Display your testimonial content */}
                                    <p>{testimonial.feedback}</p>
                                    <br></br>
                                    <p>- {testimonial.author}</p>
                                    <p>{testimonial.position}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Home;
